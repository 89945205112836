"use client";

import { Suspense } from "react";
import LoginSection from "../_components/LoginSection";

function LoginPage({ params }: { params: { lng?: string } }) {
  return <LoginSection lng={params.lng} />;
}

export default function Page({ params }: { params: { lng?: string } }) {
  return (
    <Suspense>
      <LoginPage params={params} />
    </Suspense>
  );
}
